@import url("https://fonts.googleapis.com/css2?family=Playfair:ital,opsz,wght@0,5..1200,300..900;1,5..1200,300..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@font-face {
  font-family: "ABC Corporate Extra Light";
  src: url("assets/fonts/abc-corporate-extra-light.ttf")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "AB Chanel";
  src: url("assets/fonts/abchanel.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("assets/fonts/Lato.ttf") format("truetype");
  font-style: normal;
}
body {
  margin: 0;
  font-family: "Lato", "PlayfairDisplay",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@tailwind base;

@tailwind components;

@tailwind utilities;

@layer base {
  h1 {
    font-size: 2.5rem; /* 32px */
    font-weight: 400;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    line-height: 1.2;
  }

  h2 {
    font-size: 1.5rem; /* 24px */
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1.3;
  }

  h3 {
    font-size: 1.8rem; /* 20px */
    font-weight: 300;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1.4;
  }

  h4 {
    font-size: 18px; /* 18px */
    font-weight: 700;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    line-height: 1.5;
  }

  h5 {
    font-size: 0.875rem; /* 14px */
    font-weight: 600;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    line-height: 1.6;
  }

  h6 {
    font-size: 0.75rem; /* 12px */
    font-weight: 600;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    line-height: 1.7;
  }

  ul {
    list-style-type: disc;
    margin-left: 2rem;
    margin-bottom: 1rem;
  }

  ol {
    list-style-type: decimal;
    margin-left: 2rem;
    margin-bottom: 1rem;
  }

  li {
    margin-bottom: 0.5rem;
    font-size: 16px;
     
      font-weight: 300;
  }

  p {
    font-size: 16px;
    margin-bottom: 1rem;
    font-weight: 300;
  }

  div {
    font-size: 16px;
  }

  .container {
    max-width: 1000px !important;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.enhanced-textarea::-webkit-scrollbar {
  width: 8px;
  border-radius: 0%;
}

.enhanced-textarea::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
  border-radius: 0%;
}

.enhanced-textarea::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
/* MyComponent.css */
.single-story h1,
.single-story h2,
.single-story h3,
.single-story h4,
.single-story h5,
.single-story h6,
.single-story b,
.single-story label {
  font-family: "AB Chanel", sans-serif;
  font-weight: 500 !important;
}

