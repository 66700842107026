@keyframes text-move {
  0% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}

.animate-text-move-once {
  animation: text-move 2s ease-in-out;
  animation-fill-mode: forwards;
}

/* .react-multi-carousel-item{
width: 313px !important;
} */