.blurred-content {
  user-select: none;
  pointer-events: none;
  position: relative;
}

.blurred-content p:not(:first-child),
.blurred-content li,
.blurred-content span {
  filter: blur(5px);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(1em * 1.2 * 4);
  position: relative;
}

.blurred-content p:first-of-type {
  filter: blur(0px);
}